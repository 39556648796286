import React from "react"

const Welcome = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Welcome page for authenticated users</h1>
    </div>
  )
}

export default Welcome
